<template>
  <div class="datepicker">
    <vc-date-picker
      ref="calendar"
      v-model="date"
      :attributes="attributes"
      is-expanded
      is-inline
      locale="de"
      :theme="theme"
      :from-page="page"
      @update:to-page="changeMonth"
      @update:from-page="changeMonth"
    />
    <calendar-legend
      v-if="showLegend"
      v-bind="$props"
      :days-in-month="daysInMonth"
      :empty-days="emptyDays"
      :success-days="successDays"
      :fail-days="failDays"
    />
  </div>
</template>

<script>
import { format, getDaysInMonth, isSameDay } from 'date-fns';
import Dayplan from '@/models/dayplan';
import UserProfile from '@/models/user-profile';
import { auth } from '@/firebase';
import { getNutritionLimits } from '@/helper';
import CalendarLegend from '../CalendarLegend';
import theme from './theme';

export default {
  components: {
    CalendarLegend,
  },
  props: {
    dateSelected: { type: Date, default: () => new Date() },
    showLegend: { type: Boolean, default: false },
    diagnosis: { type: Object, required: true },
  },
  data() {
    return {
      userProfile: null,
      theme,
      emptyDays: 0,
      successDays: 0,
      failDays: 0,
      daysInMonth: 31,
      daysInMonthArray: [],
      currentPage: {},
      dateNow: format(Date.now(), 'yyyy-MM-dd'), // today
      savedDates: [],
      page: {
        month: this.dateSelected.getMonth() + 1,
        year: this.dateSelected.getFullYear(),
      },
      emptyDates: {
        dot: {
          class: 'dot dot--empty',
        },
        dates: [],
      },
      successDates: {
        dot: {
          class: 'dot dot--success',
        },
        dates: [],
      },
      failDates: {
        dot: {
          class: 'dot dot--fail',
        },
        dates: [],
      },
      today: {
        dot: {
          class: 'dot dot--today',
        },
        highlight: {
          class: 'highlight-today',
        },
        dates: [new Date()],
      },
      attributes: [],
    };
  },
  computed: {
    date: {
      get() {
        return this.dateSelected;
      },
      set(val) {
        this.$emit('date', val);
      },
    },
  },
  watch: {
    currentPage: {
      handler: 'getDates',
      deep: true,
    },
    dateSelected: {
      deep: true,
      handler: 'dateChange',
    },
    '$store.state.dayplan.nutritionSums': {
      deep: true,
      handler: 'getDates',
    },
  },
  async created() {
    this.userProfile = await new UserProfile(auth.currentUser.uid).get();
  },
  methods: {
    dateChange() {
      const datePage = {
        month: this.dateSelected.getMonth() + 1,
        year: this.dateSelected.getFullYear(),
      };
      this.$store.commit('dayplan/selectDate', this.dateSelected);
      this.page = datePage;
      if (isSameDay(this.dateSelected, Date.now())) {
        this.today.highlight.class = 'highlight-today-selected';
      } else {
        this.today.highlight.class = 'highlight-today';
      }
      getNutritionLimits(this.userProfile, format(this.dateSelected, 'yyyy-MM-dd'));
    },
    changeMonth(page) {
      this.currentPage = page;
      this.daysInMonth = getDaysInMonth(new Date(page.year, page.month - 1));
    },
    getDates() {
      this.savedDates = [];
      this.successDays = 0;
      this.failDays = 0;
      Dayplan.getSavedDates().get().then((dates) => {
        dates.docs.forEach((date) => {
          const savedDate = new Date(date.id);
          const month = savedDate.getMonth() + 1;
          const year = savedDate.getFullYear();
          if (month === this.currentPage.month && year === this.currentPage.year) {
            const key = this.diagnosis.nutritionKey;
            const sums = date.data().nutritionSums;
            const limits = getNutritionLimits(this.userProfile, date.id, false);
            const inLimit = sums[key] <= limits[key];
            const offLimit = sums[key] > limits[key];
            this.savedDates.push({ date: date.id, ...{ sums, inLimit, offLimit } });
          }
        });
        this.checkmylimit();
      });
    },

    checkmylimit() {
      this.successDays = 0;
      this.failDays = 0;
      this.getDays(this.currentPage.month - 1, this.currentPage.year, this.savedDates);
      if (this.savedDates && this.savedDates.length > 0) {
        this.savedDates.forEach((date) => {
          if (date.inLimit) {
            this.successDays += 1;
            this.successDates.dates.push(new Date(date.date));
          }
          if (date.offLimit) {
            this.failDays += 1;
            this.failDates.dates.push(new Date(date.date));
          }
        });
      }
      const monthYear = this.currentPage;
      this.emptyDays = getDaysInMonth(new Date(monthYear.year, monthYear.month - 1))
        - (this.successDays + this.failDays);
      this.attributes = [this.failDates, this.successDates, this.emptyDates, this.today];
    },

    getDays(month, year, attempts) {
      for (let i = 1; i <= this.daysInMonth; i + 1) {
        const date = new Date(year, month, i);
        this.daysInMonthArray.push(date);
        i += 1;
      }
      this.addEmptyDays(attempts);
    },
    addEmptyDays(attempts) {
      const days = this.daysInMonthArray;
      attempts.forEach((attempt) => {
        days.forEach((day, i) => {
          if (new Date(attempt.date).getDate() === day.getDate()
          && Object.keys(attempt.sums).length > 0) {
            days.splice(i, 1);
          }
        });
      });
      this.emptyDates.dates = days;
      this.attributes = [...this.attributes, this.emptyDates];
    },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.vc-container {
  --day-content-width: 36px;
  --day-content-height: 36px;
  --day-content-bg-color-hover: transparent;
  --day-content-bg-color-focus: transparent;
  height: 480px;

  .vc-grid-container {
    overflow: unset;
  }

  .vc-title {
    font-size: 15px;
    line-height: 2;
  }

  .vc-text-sm {
    font-size: 13px;
  }
  .vc-weeks {
    row-gap: 24px !important;
  }

}

.cal-day {
  &--empty {
    border: 1px solid config.$color-mid;
    background-color: transparent;
  }

  &--today {
    background-color: config.$brand-primary;
  }

  &--success {
    background-color: config.$brand-secondary;
  }

  &--fail {
    background-color: #6b6b6b;
  }
}
.dot {
  width: 18px !important;
  height: 18px !important;
  position: absolute;
  bottom: -16px;
  &:not(:last-child) {
    margin-right: 0 !important;
  }
  &--success::after, &--fail::after {
    color: #FFF;
    font-weight: 700;
    font-size: 11px;
    position: absolute;
    left: 4px;
    bottom: 1px;
  }
  &--empty, &--today {
    background: none;
    border: 1px solid #6b6b6b;
  }
  &--success {
    background-color: config.$brand-secondary;
    &:after {
      content: "✓";
    }
  }
  &--fail {
    background-color: #6b6b6b;
    &:after {
      content: "✕";
      left: 5px;
    }
  }
}
.cal-legend {
  margin-top: 24px;
}
.datepicker {
  width: 100%;
  max-width: config.$max-width-lg;
  margin: auto;
}
.vc-highlight {
  background-color: #a4d2fc;
  .is-today & {
    border: 2px solid #3792e8;
    background-color: #fff;
  }
  &.highlight-today-selected {
    background-color: #a4d2fc;
    z-index: 9;
  }
}
.vc-day-content {
  color: #000000;
  font-weight: 500;
}
</style>

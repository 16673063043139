<template>
  <div class="indicator">
    <van-icon
      :name="$icon(iconName)"
      size="22"
    />
    <span class="indicator__title">{{ title }}</span>
    <span
      v-if="description"
      class="indicator__description"
    >{{ description }}</span>
    <span
      v-if="showSummary"
      class="indicator__value"
    >{{ days }}/{{ month }} {{ $t('days') }}</span>
  </div>
</template>

<script>
export default {
  props: {
    iconName: { type: String, default: null },
    title: { type: String, default: '' },
    description: { type: String, default: null },
    showSummary: { type: Boolean, default: true },
    days: { type: Number, default: 0 },
    month: { type: Number, default: 31 },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.indicator__icon {
  display: block;
  width: 22px;
  height: 22px;
  border: solid 1px #898989;
  border-radius: 50%;
  background-color: #ffffff;

  &--cross {
    background-color: config.$color-mid;
  }

  &--check {
    border-color: config.$brand-secondary;
    background-color: config.$brand-secondary;
  }
}

.indicator__title,
.indicator__description {
  margin-top: 0.8em;
  font-size: config.$font-size-xs;
  line-height: 1.27;
  text-align: center;
  color: #242526;
}

.indicator__value {
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.7;
  color: #242526;
}
</style>

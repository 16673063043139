<template>
  <div class="cal-legend">
    <indicator
      icon-name="calendar-empty"
      :title="$t('calendar.empty')"
      :days="emptyDays"
      :month="daysInMonth"
    />
    <indicator
      icon-name="calendar-check"
      :title="$t('calendar.success')"
      :days="successDays"
      :month="daysInMonth"
    />
    <indicator
      icon-name="calendar-cross"
      :title="$t('calendar.fail')"
      :days="failDays"
      :month="daysInMonth"
    />
  </div>
</template>

<script>
import Indicator from '../Indicator';

export default {
  components: {
    Indicator,
  },
  props: {
    emptyDays: { type: Number, default: 0 },
    successDays: { type: Number, default: 0 },
    failDays: { type: Number, default: 0 },
    daysInMonth: { type: Number, default: 31 },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.cal-legend {
  display: flex;

  .indicator {
    flex: 1;
  }
}
</style>
